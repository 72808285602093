import SmoothScroll from 'smooth-scroll'
const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    offset: function (anchor, navigation) {
        if (screen.width <= 640) {
            return 80
        } else {
            return 92
        }
    },
    easing: 'easeInOutQuint',
    updateURL: false,
    popstate: false
})



// Google Analytics
const btnApplications = document.querySelectorAll(".btn_application a")
btnApplications.forEach(btnApplication => {
    btnApplication.addEventListener("click", e => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: 'applicantClick' })
    })
})



/* *** *** *** *** *** *** *** *** *** ***
** SP用インターン検索　アコーディオン開閉アニメーション
*** *** *** *** *** *** *** *** *** *** */
// const accordionTrigger = document.querySelectorAll(".accordion dt")
// const accordionTarget = document.querySelectorAll(".accordion dd")

// // 高さを取得、data属性に保存
// accordionTarget.forEach(target => {
//     const height = target.clientHeight
//     target.dataset.height = height
//     if (!target.parentNode.classList.contains("current")) {
//         target.style.height = 0 + "px"
//     }else{
//         target.style.height = target.dataset.height + "px"
//     }
// })

// //イベント
// // 親に.currentがあれば閉じる、なければ.currentをつけてdata属性の数値分高さを与える
// accordionTrigger.forEach(trigger => {
//     trigger.addEventListener("click", event =>{
//         if (event.target.parentNode.classList.contains("current")){
//             event.target.parentNode.classList.remove("current")
//             event.target.nextElementSibling.style.height = 0
//         }else{
//             event.target.parentNode.classList.add("current")
//             event.target.nextElementSibling.style.height = event.target.nextElementSibling.dataset.height + "px"
//         }
//     })
// })

// const tableWrapHeight = tableWrap.clientHeight
// tableWrap.dataset.height = tableWrapHeight
// if (window.innerWidth <= 640) {
//     tableWrap.style.height = 430 + "px"
//     btnMore.addEventListener("click", event => {
//         event.target.classList.add("hide")
//         tableWrap.style.height = tableWrap.dataset.height + "px"
//     })
// } else {
//     tableWrap.style.height = "auto"
// }





const html = document.querySelector("html");
const body = document.querySelector("body");
const btnTimetable = document.querySelector("#btn_timetable");
const timetableModal = document.querySelector("#timetable_modal");
const btn_modal_close = document.querySelector("#btn_modal_close");

btnTimetable.addEventListener("click", event => {
    html.classList.add("modal_open")
    body.classList.add("modal_open")
    timetableModal.classList.add("open")
})

btn_modal_close.addEventListener("click", event => {
    html.classList.remove("modal_open")
    body.classList.remove("modal_open")
    timetableModal.classList.remove("open")
})

// 枠の外をクリックしたとき
timetableModal.addEventListener('click', event => {
    if(event.target.closest('#timetable_contents_wrap') === null) {
        html.classList.remove("modal_open")
        body.classList.remove("modal_open")
        timetableModal.classList.remove("open")
    }
});

document.addEventListener('keydown', event => {
    if(event.key === 'Escape'){
        if(timetableModal.classList.contains("open")){
            html.classList.remove("modal_open")
            body.classList.remove("modal_open")
            timetableModal.classList.remove("open")
        }
    }
})
